<template>
  <nav
    v-cloak
    v-show="!$route?.query?.mobile_report"
    class="navbar-default navbar-static-side"
    role="navigation">
    <div class="sidebar-collapse">
      <div class="nav-header text-center">
        <div class="backbar-logo-container">
          <img class="backbar-logo" src="/images/icons/logo-standalone.png" />
          <h2 class="mt-3">BACKBAR</h2>
        </div>
      </div>
      <ul
        id="side-menu"
        class="nav metismenu"
        v-bind:style="{
          opacity: currentVenue && currentVenue.id ? '1' : '0.6',
          pointerEvents: !(currentVenue && currentVenue.id) ? 'none' : 'auto'
        }">
        <li class="">
          <router-link to="/">
            <i class="fas fa-tachometer-alt"></i>
            <span class="nav-label">Dashboard</span>
          </router-link>
        </li>

        <li id="inventory-menu">
          <a>
            <i class="fas fa-pen-square"></i>
            <span class="nav-label">Inventory</span>
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li v-if="user?.permissions?.crud_items || user?.user_level === 9">
              <router-link to="/inventory/manage">Inventory items</router-link>
            </li>
            <li v-if="user?.permissions?.crud_items || user?.user_level === 9">
              <router-link to="/inventory/recipes">Recipes</router-link>
            </li>

            <li v-if="user?.permissions?.take_inventory || user.user_level === 9">
              <router-link to="/inventory/audit">Take inventory</router-link>
            </li>
            <li v-if="user?.permissions?.start_end_inventory || user.user_level === 9">
              <router-link to="/inventory/locations">Manage locations</router-link>
            </li>
            <li
              v-if="
                (currentOrganization.plan >= 2 && user?.permissions?.crud_items) ||
                user?.user_level === 9
              ">
              <router-link to="/inventory/manage-par">Manage pars</router-link>
            </li>
          </ul>
        </li>
        <li id="orders-menu">
          <a>
            <i class="fas fa-shopping-basket"></i>
            <span class="nav-label">Orders</span>
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li v-if="user?.permissions?.add_to_shopping_cart || user?.user_level === 9">
              <router-link to="/orders/build">Build orders</router-link>
            </li>
            <li
              v-if="
                (user?.permissions?.add_to_shopping_cart && user?.permissions?.place_orders) ||
                user?.user_level === 9
              ">
              <router-link to="/orders/cart">Shopping cart</router-link>
            </li>
            <li v-if="user?.permissions?.manage_orders || user?.user_level === 9">
              <router-link to="/orders/history">Order history</router-link>
            </li>
            <li v-if="user?.permissions?.manage_vendors || user?.user_level === 9">
              <router-link to="/orders/vendors">Manage vendors</router-link>
            </li>
          </ul>
        </li>

        <li v-if="user?.permissions?.modify_menu_preferences || user?.user_level === 9">
          <router-link to="/menu/manage">
            <i class="fas fa-map"></i>
            <span class="nav-label">Menu</span>
          </router-link>
        </li>
        <li v-if="user?.permissions?.manage_pos || user?.user_level === 9" id="sales-menu">
          <a>
            <i class="fas fa-cash-register"></i>
            <span class="nav-label">Sales</span>
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li v-if="user?.permissions?.access_sales_reports || user?.user_level === 9">
              <router-link to="/sales/dashboard">Dashboard</router-link>
            </li>
            <li
              v-if="
                currentVenue.pos &&
                currentVenue.pos.status === 'connected' &&
                currentVenue.pos.has_location === 1 &&
                ((user && user.permissions) || user?.user_level === 9)
              ">
              <router-link to="/sales/map-items">Map items</router-link>
            </li>
            <li
              v-if="
                currentVenue.pos &&
                currentVenue.pos.status === 'connected' &&
                currentVenue.pos.has_location === 1 &&
                ((user && user.permissions) || user?.user_level === 9)
              ">
              <router-link to="/sales/map-modifiers">Modifiers</router-link>
            </li>
            <li
              v-if="
                currentVenue.pos &&
                currentVenue.pos.status === 'connected' &&
                currentVenue.pos.has_location === 1 &&
                ((user && user.permissions) || user?.user_level === 9)
              ">
              <router-link to="/sales/settings">Settings</router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            user &&
            user.permissions &&
            (user?.permissions?.access_inventory_reports ||
              user?.permissions?.access_order_reports ||
              user?.user_level === 9)
          ">
          <router-link to="/reports">
            <i class="fas fa-chart-line"></i>
            <span class="nav-label">Reports</span>
          </router-link>
        </li>
        <li id="support-menu">
          <a>
            <i class="fas fa-question-circle"></i>
            <span class="nav-label">Support</span>
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li v-if="currentVenue.vip_retailer_id" id="getting-started">
              <router-link to="/getting-started">
                <span>Getting started</span>
              </router-link>
            </li>
            <li>
              <a href="https://www.getbackbar.com/support" target="_blank">Help center</a>
            </li>
            <li>
              <a href="https://www.getbackbar.com/webinar-signup" target="_blank">
                Training webinars
              </a>
            </li>
            <li>
              <a href="#" @click.prevent="openSupportModal">Submit support ticket</a>
            </li>
          </ul>
        </li>
        <li v-if="user?.user_level >= 5" id="admin-menu">
          <a>
            <i class="fas fa-tools"></i>
            <span class="nav-label">Admin</span>
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li>
              <router-link to="/admin/manage-organizations">Organizations</router-link>
            </li>
            <li>
              <router-link to="/admin/manage-users">Users</router-link>
            </li>
            <li>
              <router-link to="/admin/manage-orders">Orders</router-link>
            </li>
            <li>
              <router-link to="/admin/master-items">Master items</router-link>
            </li>
            <li>
              <router-link to="/admin/link-master-items">Link master items</router-link>
            </li>
            <li>
              <router-link to="/admin/merge-records">Merge records</router-link>
            </li>
            <li>
              <router-link to="/admin/review-queue">Review queue</router-link>
            </li>
            <li>
              <router-link to="/admin/manage-plans">Manage plans</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script lang="ts">
import $ from 'jquery'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user.js'
import { defineComponent } from 'vue'
import { useSupportModalStore } from '@/stores/support-modal'

export default defineComponent({
  computed: {
    ...mapState(useUserStore, ['user', 'currentVenue', 'currentOrganization'])
  },
  mounted() {
    $('document').ready(() => {
      this.handleURLChange()
    })
  },
  methods: {
    ...mapActions(useSupportModalStore, ['openSupportModal']),
    handleURLChange() {
      const currentCategory = this.findCurrentCategory()

      if (currentCategory) {
        this.toggleMenuForCategory(currentCategory)
      } else {
        this.closeAllMenus()
      }
    },

    findCurrentCategory() {
      const CATEGORIES = ['inventory', 'orders', 'admin', 'sales']
      return CATEGORIES.find((category) => window.location.href.includes(category))
    },

    toggleMenuForCategory(category: string) {
      const menuElement = document.getElementById(`${category}-menu`)
      if (!menuElement) return

      const isMenuOpen = menuElement.querySelector('ul')?.classList.contains('mm-show')
      if (!isMenuOpen) {
        menuElement.querySelector('a')?.click()
      }
    },

    closeAllMenus() {
      const ACTIVE_CLASSES = ['mm-show', 'mm-active']
      ACTIVE_CLASSES.forEach((className) => {
        const elements = document.getElementsByClassName(className)
        Array.from(elements).forEach((element) => element.classList.remove(className))
      })
    }
  },
  watch: {
    $route: 'handleURLChange'
  }
})
</script>
