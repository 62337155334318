import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/views/layouts/DefaultLayout.vue'
import LoginLayout from '@/views/layouts/LoginLayout.vue'
import PageNotFoundView from '@/views/error/PageNotFoundView.vue'
import SignupLayout from '@/views/layouts/SignupLayout.vue'
import ForgotPasswordLayout from '@/views/layouts/ForgotPasswordLayout.vue'
import ErrorLayout from '@/views/layouts/ErrorLayout.vue'
import { useCountriesStore } from '@/stores/countries.js'
import { useStatesStore } from '@/stores/states.js'
import auth from '@/middleware/auth.js'
import venue from '@/middleware/venue.js'
import venuePos from '@/middleware/venue-pos.js'
import userLevel from '@/middleware/user-level.js'
import paymentPlan from '@/middleware/user-level.js'
import permission from '@/middleware/permission.js'
import { UserPermissionsEnum } from '@/enums/user-permissions.enum'
import { useUserStore } from '@/stores/user.js'
import { useAlertsStore } from '@/stores/alerts.js'
import SquareCallbackComponent from '@/components/SquareCallbackComponent.vue'
import CloverCallbackComponent from '@/components/CloverCallbackComponent.vue'
import Shift4CallbackComponent from '@/components/Shift4CallbackComponent.vue'
import { useItemsStore } from '@/stores/items.js'
import { useGtmStore } from '@/stores/google-tag-manager.js'
import type { UserPermissionsType } from '@/types/user-permissions.type.js'
import { useDidbuyAuditLogStore } from '@/stores/didbuy-audit-log.js'
import { useVenuePosStore } from '@/stores/venue-pos.js'
import { useFeatureFlagsStore } from '@/stores/feature-flags.js'
import { useShoppingCartStore } from '@/stores/shopping-cart'

interface RouteContext {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
  next: NavigationGuardNext
  userLevel?: number
}

const router = createRouter({
  history: createWebHistory(import.meta.env.FE_BASE_URL),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/index/DashboardView.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Beverage Dashboard',
        middleware: [auth, venue],
        scroll: false
      },
      beforeEnter: (to, from, next) => {
        const plan = window.sessionStorage.getItem('plan')
        if (plan) {
          next({
            name: 'organization-plans'
          })
        } else {
          next()
        }
      }
    },
    {
      path: '/getting-started',
      name: 'getting-started',
      component: () => import('@/views/signup/GettingStarted.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Welcome to Backbar',
        middleware: [auth, venue],
        scroll: true
      },
      beforeEnter: (to, from, next) => {
        const userStore = useUserStore()
        if (!userStore.currentVenue.vip_retailer_id) {
          next({ name: 'dashboard' })
        }
        next()
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/LoginView.vue'),
      meta: {
        layout: LoginLayout,
        title: 'Backbar | User Login'
      }
    },
    {
      path: '/create-organization',
      name: 'create-organization',
      component: () => import('@/views/signup/CreateOrgView.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Backbar | Organization',
        middleware: [auth]
      }
    },
    {
      path: '/verify-account',
      alias: '/verify-flow',
      name: 'verify-account-parent',
      children: [
        {
          path: '',
          name: 'verify-account',
          component: () => import('@/views/signup/VerifyAccountVip.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Backbar | Verify Account',
            middleware: [auth, venue]
          }
        },
        {
          path: 'invoice',
          name: 'verify-account-invoice',
          component: () => import('@/views/signup/VerifyAccountInvoice.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Backbar | Verify Account',
            middleware: [auth, venue]
          }
        }
      ]
    },
    {
      path: '/signup',
      name: 'signup-parent',
      children: [
        {
          path: '',
          name: 'signup',
          component: () => import('@/views/signup/SignupView.vue'),
          meta: {
            layout: SignupLayout,
            title: 'Backbar | User Signup'
          }
        },
        {
          path: 'confirm',
          name: 'signup-confirm',
          component: () => import('@/views/signup/ConfirmView.vue'),
          meta: {
            layout: SignupLayout,
            title: 'Backbar | Verify your email'
          }
        },
        {
          path: 'verify-account-new',
          name: 'signup-verify-account-new',
          component: () => import('@/views/signup/VerifyAccountVip.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Backbar | Verify Account',
            middleware: [auth, venue]
          }
        },
        {
          path: 'verify-account',
          name: 'signup-verify-account',
          component: () => import('@/views/signup/VerifyAccountInvoice.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Backbar | Verify Account',
            middleware: [auth]
          }
        }
      ]
    },
    {
      path: '/create-inventory-locations',
      name: 'create-inventory-locations',
      component: () => import('@/views/signup/CreateInventoryLocationsView.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Backbar | Create Inventory Locations',
        middleware: [auth, venue]
      }
    },
    {
      path: '/distributor-information',
      name: 'distributor-information',
      component: () => import('@/views/signup/DistributorInformationView.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Backbar | Distributor Information',
        middleware: [auth, venue]
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/account/ForgotPasswordView.vue'),
      meta: {
        layout: ForgotPasswordLayout,
        title: 'Backbar | Forgot Password'
      }
    },
    {
      path: '/forgot-password/:reset_id',
      name: 'forgot-password-reset',
      component: () => import('@/views/account/ResetForgottenPasswordView.vue'),
      meta: {
        layout: ForgotPasswordLayout,
        title: 'Backbar | Forgot Password'
      }
    },
    {
      path: '/upload-inventory-spreadsheet',
      name: 'upload-inventory-spreadsheet',
      component: () => import('@/views/signup/UploadInventorySpreadsheetView.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Backbar | Upload Spreadsheet'
      }
    },
    {
      path: '/iebrowser',
      name: 'iebrowser',
      component: () => import('@/views/error/IEBrowserErrorView.vue'),
      meta: {
        layout: ErrorLayout,
        title: 'Backbar | Unsupported Browser'
      }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () => import('@/views/signup/WelcomeView.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Backbar | Welcome',
        middleware: [auth]
      }
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('@/views/account/AccountSettingsView.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Account Settings',
        middleware: [auth]
      }
    },
    {
      path: '/referral',
      name: 'referral',
      component: () => import('@/views/referral/ReferralView.vue'),
      meta: {
        layout: DefaultLayout,
        title: 'Refer a venue and get rewarded',
        middleware: [auth, venue]
      }
    },
    {
      path: '/organization',
      name: 'organization',
      children: [
        {
          path: 'plan',
          name: 'organization-plan',
          component: () => import('@/views/organization/manage-organization/ManagePlanView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Plan',
            middleware: [auth, permission(UserPermissionsEnum.ModifyBilling)]
          }
        },
        {
          path: 'users',
          name: 'organization-users',
          component: () => import('@/views/organization/manage-users/ManageUsersView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Users',
            middleware: [auth]
          }
        },
        {
          path: 'manage',
          name: 'organization-manage',
          component: () =>
            import('@/views/organization/manage-organization/ManageOrganizationView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Organization',
            middleware: [auth, permission(UserPermissionsEnum.ModifyOrganization)]
          }
        },
        {
          path: 'plan',
          name: 'organization-plan',
          component: () => import('@/views/organization/manage-organization/ManagePlanView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Plan',
            middleware: [auth, permission(UserPermissionsEnum.ModifyBilling)]
          }
        },
        {
          path: 'invoices',
          name: 'organization-invoices',
          component: () =>
            import('@/views/organization/manage-organization/BillingHistoryView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Billing History',
            middleware: [auth, permission(UserPermissionsEnum.ModifyBilling)]
          }
        },
        {
          path: 'plans',
          name: 'organization-plans',
          component: () => import('@/views/organization/manage-organization/ChangePlanView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Change Plan',
            middleware: [auth, permission(UserPermissionsEnum.ModifyBilling)]
          }
        }
      ]
    },
    {
      path: '/menu',
      name: 'menu',
      children: [
        {
          path: 'preferences',
          name: 'menu-preferences',
          component: () => import('@/views/menu/MenuPreferencesView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Menu Preferences',
            middleware: [auth, venue, permission(UserPermissionsEnum.ModifyMenuPreferences)]
          }
        },
        {
          path: 'designer',
          name: 'menu-designer',
          component: () => import('@/views/menu/MenuDesignerView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Menu Designer',
            middleware: [auth, venue]
          }
        },
        {
          path: 'manage',
          name: 'menu-manage',
          component: () => import('@/views/menu/UserManagementView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Widget',
            middleware: [auth, venue]
          }
        },
        {
          path: 'add',
          name: 'menu-add',
          component: () => import('@/views/menu/MenuAddView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Add Item',
            middleware: [auth, venue]
          }
        },
        {
          path: 'add/drink',
          name: 'menu-add-drink',
          component: () => import('@/views/menu/add-drink/AddDrinkView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Add Drink',
            middleware: [auth, venue]
          }
        },
        {
          path: 'add/item',
          name: 'menu-add-item',
          component: () => import('@/views/menu/add-item/AddItemView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Add Item',
            middleware: [auth, venue]
          }
        },
        {
          path: 'add/recipe',
          name: 'menu-add-recipe',
          component: () => import('@/views/menu/add-recipe/AddRecipeView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Add Recipe',
            middleware: [auth, venue]
          }
        },
        {
          path: 'edit/drink',
          name: 'menu-edit-drink',
          component: () => import('@/views/menu/edit-drink/EditDrinkView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Edit Drink',
            middleware: [auth, venue]
          }
        },
        {
          path: 'edit/item',
          name: 'menu-edit-item',
          component: () => import('@/views/menu/edit-item/EditItemView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Edit Item',
            middleware: [auth, venue]
          }
        },
        {
          path: 'edit/recipe',
          name: 'menu-edit-recipe',
          component: () => import('@/views/menu/edit-recipe/EditRecipeView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Edit Recipe',
            middleware: [auth, venue]
          }
        },
        {
          path: '/:id',
          name: 'menu-widget',
          component: () => import('@/views/menu/MenuWidgetView.vue')
        }
      ]
    },
    {
      path: '/inventory',
      name: 'inventory',
      children: [
        {
          path: 'spreadsheet-upload-wizard',
          name: 'inventory-spreadsheet-upload-wizard',
          component: () =>
            import('@/views/inventory/spreadsheet-upload-wizard/SpreadsheetUploadWizardView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Upload Inventory Spreadsheet',
            middleware: [auth, venue]
          }
        },
        // Four steps of new import wizard is listed below
        {
          path: 'import-wizard/1',
          name: 'import-wizard/1',
          component: () =>
            import('@/views/inventory/inventory-items-import-wizard/UploadInventoryFile.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Backbar | Upload Spreadsheet',
            middleware: [auth, venue]
          }
        },
        {
          path: 'import-wizard/2',
          name: 'mport-wizard/2',
          component: () =>
            import('@/views/inventory/inventory-items-import-wizard/MatchColumnsAndValidation.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Backbar | Upload Spreadsheet',
            middleware: [auth, venue]
          }
        },
        {
          path: 'import-wizard/3',
          name: 'import-wizard/3',
          component: () =>
            import('@/views/inventory/inventory-items-import-wizard/MatchItemsToBackbar.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Backbar | Upload Spreadsheet',
            middleware: [auth, venue]
          }
        },
        {
          path: 'tutorial',
          name: 'inventory-tutorial',
          component: () => import('@/views/inventory/tutorial/TutorialLocationsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Learn To Count Inventory',
            middleware: [auth, venue]
          }
        },
        {
          path: 'tutorial/audit',
          name: 'inventory-tutorial-audit',
          component: () => import('@/views/inventory/tutorial/TutorialSessionView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Learn To Count Inventory',
            middleware: [auth, venue]
          }
        },
        {
          path: 'custom-sort/:location_id',
          name: 'inventory-custom-sort-id',
          component: () => import('@/views/inventory/CustomSortView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Custom sort inventory',
            middleware: [auth, venue, permission(UserPermissionsEnum.StartEndInventory)]
          }
        },
        {
          path: 'manage-par',
          name: 'inventory-manage-par',
          component: () => import('@/views/inventory/ManageParView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Par Manager',
            middleware: [auth, venue, paymentPlan(0)]
          }
        },
        {
          path: 'audit',
          name: 'inventory-audit',
          component: () => import('@/views/inventory/audit/AuditView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Count Inventory',
            middleware: [auth, venue, permission(UserPermissionsEnum.StartEndInventory)]
          }
        },
        {
          path: 'audit/locations',
          name: 'inventory-audit-locations',
          component: () => import('@/views/inventory/audit/AuditLocationsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Count Inventory',
            middleware: [auth, venue, permission(UserPermissionsEnum.TakeInventory)]
          }
        },
        {
          path: 'audit/locations/:id',
          name: 'inventory-audit-locations-id',
          component: () => import('@/views/inventory/audit/AuditSessionView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Count Inventory',
            middleware: [auth, venue, permission(UserPermissionsEnum.TakeInventory)]
          }
        },
        {
          path: 'audit/summary',
          name: 'inventory-audit-summary',
          component: () => import('@/views/inventory/audit/InventorySessionSummaryView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Inventory Session Summary',
            middleware: [auth, venue, permission(UserPermissionsEnum.StartEndInventory)]
          }
        },
        {
          path: 'audit/end',
          name: 'inventory-audit-end',
          component: () => import('@/views/inventory/audit/AuditEndView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'End Inventory Session',
            middleware: [auth, venue]
          }
        },
        {
          path: 'manage',
          name: 'inventory-manage',
          component: () =>
            import('@/views/inventory/manage-items-inventory/ManageItemsInventoryView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Inventory Items',
            middleware: [auth, venue, permission(UserPermissionsEnum.CrudItems)]
          }
        },
        {
          path: 'recipes',
          name: 'inventory-recipes',
          component: () =>
            import('@/views/inventory/manage-recipes-inventory/ManageRecipesInventoryView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Recipes',
            middleware: [auth, venue]
          }
        },
        {
          path: 'add',
          name: 'inventory-add',
          component: () => import('@/views/menu/MenuAddView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Add Inventory Item',
            middleware: [auth, venue, permission(UserPermissionsEnum.CrudItems)]
          }
        },
        {
          path: 'add/item',
          name: 'inventory-add-item',
          component: () => import('@/views/menu/add-item/AddItemView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Add Item',
            middleware: [auth, venue, permission(UserPermissionsEnum.CrudItems)]
          }
        },
        {
          path: 'add/recipe',
          name: 'inventory-add-recipe',
          component: () => import('@/views/menu/add-recipe/AddRecipeView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Add Recipe',
            middleware: [auth, venue]
          }
        },
        {
          path: 'add/drink',
          name: 'inventory-add-drink',
          component: () => import('@/views/menu/add-drink/AddDrinkView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Add Drink',
            middleware: [auth, venue, permission(UserPermissionsEnum.CrudItems)]
          }
        },
        {
          path: 'edit',
          meta: {
            layout: DefaultLayout,
            middleware: [auth, venue, permission(UserPermissionsEnum.CrudItems)]
          },
          children: [
            {
              path: 'drink',
              name: 'inventory-edit-drink',
              component: () => import('@/views/menu/edit-drink/EditDrinkView.vue'),
              meta: {
                title: 'Edit Drink'
              }
            },
            {
              path: 'item',
              name: 'inventory-edit-item',
              component: () => import('@/views/menu/edit-item/EditItemView.vue'),
              meta: {
                title: 'Edit Item'
              }
            },
            {
              path: 'recipe',
              name: 'inventory-edit-recipe',
              component: () => import('@/views/menu/edit-recipe/EditRecipeView.vue'),
              meta: {
                title: 'Edit Recipe'
              }
            }
          ]
        },
        {
          path: 'locations',
          name: 'inventory-locations',
          component: () => import('@/views/inventory/ManageLocationsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Inventory Locations',
            middleware: [auth, venue]
          }
        }
      ]
    },
    {
      path: '/orders',
      name: 'orders',
      children: [
        {
          path: 'vendors',
          name: 'orders-vendors',
          component: () => import('@/views/orders/VendorsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Vendors',
            middleware: [auth, venue, permission(UserPermissionsEnum.ManageVendors)]
          }
        },
        {
          path: 'build',
          name: 'orders-build',
          component: () => import('@/views/orders/BuildView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Build Orders',
            middleware: [auth, venue, permission(UserPermissionsEnum.PlaceOrders)]
          }
        },
        {
          path: 'history',
          name: 'orders-history',
          component: () => import('@/views/orders/HistoryView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Order History',
            middleware: [auth, venue, permission(UserPermissionsEnum.ManageOrders)]
          }
        },
        {
          path: 'edit/:id',
          name: 'orders-edit-id',
          component: () => import('@/views/orders/OrderDetailsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Edit Order',
            middleware: [auth, venue, permission(UserPermissionsEnum.ManageOrders)]
          }
        },
        {
          path: 'cart',
          name: 'orders-cart',
          component: () => import('@/views/orders/CartView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Shopping Cart',
            middleware: [auth, venue, permission(UserPermissionsEnum.AddToShoppingCart)]
          }
        }
      ]
    },
    {
      path: '/reports',
      alias: '/reports/dashboard',
      name: 'reports',
      children: [
        {
          path: '',
          name: 'reports-dashboard',
          alias: 'pos-auth/square-callback',
          component: () => import('@/views/reports/reports-dashboard/ReportsDashboardView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Reports Dashboard',
            middleware: [auth, venue]
          }
        },
        {
          path: 'inventory-overview',
          name: 'reports-inventory-overview',
          component: () => import('@/views/reports/InventoryOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Inventory Overview Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'inventory-details',
          name: 'reports-inventory-details',
          component: () => import('@/views/reports/InventoryDetailsReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Inventory Details Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'session-overview',
          name: 'reports-session-overview',
          component: () => import('@/views/reports/SessionOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Session Overview Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'session-details',
          name: 'reports-session-details',
          component: () => import('@/views/reports/SessionDetailsReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Session Details Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'inventory-trends-overview',
          name: 'reports-inventory-trends-overview',
          component: () => import('@/views/reports/InventoryTrendsOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Inventory Trends Overview Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'inventory-trends-comparison',
          name: 'reports-inventory-trends-comparison',
          component: () => import('@/views/reports/InventoryTrendsComparisonReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Inventory Trends Comparison Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'comparison-overview',
          name: 'reports-comparison-overview',
          component: () => import('@/views/reports/ComparisonOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Comparison Overview Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'comparison-details',
          name: 'reports-comparison-details',
          component: () => import('@/views/reports/ComparisonDetailsReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Comparison Details Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'excess-inventory',
          name: 'reports-excess-inventory',
          component: () => import('@/views/reports/ExcessInventoryReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Excess Inventory Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'spend-by-vendor',
          name: 'reports-spend-by-vendor',
          component: () => import('@/views/reports/SpendByVendorReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Spend By Vendor Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'spend-overview',
          name: 'reports-spend-overview',
          component: () => import('@/views/reports/SpendOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Spend Overview Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'sales-overview',
          name: 'reports-sales-overview',
          component: () => import('@/views/reports/SalesOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Sales Overview Report',
            middleware: [auth, venue, permission(UserPermissionsEnum.AccessSalesReports)]
          }
        },
        {
          path: 'sales-trends-overview',
          name: 'reports-sales-trends-overview',
          component: () => import('@/views/reports/SalesTrendsOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Sales Trends Overview Report',
            middleware: [auth, venue, permission(UserPermissionsEnum.AccessSalesReports)]
          }
        },
        {
          path: 'sales-trends-comparison',
          name: 'reports-sales-trends-comparison',
          component: () => import('@/views/reports/SalesTrendsComparisonReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Sales Trends Comparison Report',
            middleware: [auth, venue, permission(UserPermissionsEnum.AccessSalesReports)]
          }
        },
        {
          path: 'order-item-details',
          name: 'reports-order-item-details',
          component: () => import('@/views/reports/OrderItemDetailsReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Order Item Details Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'order-items-itemized',
          name: 'reports-order-items-itemized',
          component: () => import('@/views/reports/OrderItemsItemizedReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Order Items Itemized Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'spending-trends-overview',
          name: 'reports-spending-trends-overview',
          component: () => import('@/views/reports/SpendingTrendsOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Spending Trends Overview Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'order-trends-comparison',
          name: 'reports-order-trends-comparison',
          component: () => import('@/views/reports/OrderTrendsComparisonReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Order Trends Comparison Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'usage-trends',
          name: 'reports-usage-trends',
          component: () => import('@/views/reports/UsageTrendsReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Usage Trends Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'usage-details',
          name: 'reports-usage-details',
          component: () => import('@/views/reports/UsageDetailsReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Usage Details Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'variance-details',
          name: 'reports-variance-details',
          component: () => import('@/views/reports/VarianceDetailsReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Variance Details Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'cost-tracker',
          name: 'reports-cost-tracker',
          component: () => import('@/views/reports/CostTrackerReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Cost Tracker Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'cost-goods-sold-overview',
          name: 'reports-cost-goods-sold-overview',
          component: () => import('@/views/reports/CostOfGoodsSoldOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Cost of Goods Sold Overview',
            middleware: [auth, venue]
          }
        },
        {
          path: 'cost-of-goods-sold-details',
          name: 'reports-cost-of-goods-sold-details',
          component: () => import('@/views/reports/CostOfGoodsSoldDetailsReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Cost of Goods Sold Details',
            middleware: [auth, venue, permission(UserPermissionsEnum.AccessSalesReports)]
          }
        },
        {
          path: 'organization-overview',
          name: 'reports-organization-overview',
          component: () => import('@/views/reports/OrganizationOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Organization Inventory Overview by Venue Location',
            middleware: [auth, venue]
          }
        },
        {
          path: 'inventory-prices',
          name: 'reports-inventory-prices',
          component: () => import('@/views/reports/InventoryPricesReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Pricing, Pour Costs & Profit Report',
            middleware: [auth, venue]
          }
        },
        {
          path: 'profit-overview',
          name: 'reports-profit-overview',
          component: () => import('@/views/reports/ProfitOverviewReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Profit Details',
            middleware: [auth, venue]
          }
        },
        {
          path: 'transfers',
          name: 'reports-transfers',
          component: () => import('@/views/reports/TransferReportView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Transfer Report',
            middleware: [auth, venue]
          }
        }
      ]
    },
    {
      path: '/sales',
      name: 'sales',
      children: [
        {
          path: 'enter-sales',
          name: 'sales-enter-sales',
          component: () => import('@/views/sales/manually-enter-sales/EnterSalesDataView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Enter Sales Manually',
            middleware: [auth, venue]
          }
        },
        {
          path: 'dashboard',
          name: 'sales-dashboard',
          component: () => import('@/views/sales/dashboard/SalesDashboardView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Sales Dashboard',
            middleware: [auth, venue, permission(UserPermissionsEnum.ManagePos)]
          }
        },
        {
          path: 'map-items',
          name: 'sales-map-items',
          component: () => import('@/views/sales/map-items/MapItemsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Map Items',
            middleware: [auth, venue, venuePos, permission(UserPermissionsEnum.ManagePos)]
          }
        },
        {
          path: 'map-modifiers',
          name: 'sales-map-modifiers',
          component: () => import('@/views/sales/map-modifiers/MapModifiersView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Map Modifiers',
            middleware: [auth, venue, venuePos, permission(UserPermissionsEnum.ManagePos)]
          }
        },
        {
          path: 'settings',
          name: 'sales-settings',
          component: () => import('@/views/sales/settings/SalesSettingsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Sales Settings',
            middleware: [auth, venue, venuePos, permission(UserPermissionsEnum.ManagePos)]
          }
        },
        {
          path: 'settings/serving-sizes',
          name: 'sales-settings-serving-sizes',
          component: () => import('@/views/sales/settings/ServingSizesView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Set Standard Serving Sizes',
            middleware: [auth, venue, venuePos, permission(UserPermissionsEnum.ManagePos)]
          }
        },
        {
          path: 'settings/connect-pos',
          name: 'sales-settings-connect-pos',
          component: () => import('@/views/sales/settings/connect-pos/ConnectPosView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Connect POS system',
            middleware: [auth, venue, permission(UserPermissionsEnum.ManagePos)]
          }
        }
      ]
    },
    {
      path: '/admin',
      name: 'admin',
      children: [
        {
          path: 'master-items',
          name: 'admin-master-items',
          component: () => import('@/views/admin/ManageMasterItemsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Master Items',
            middleware: [auth, userLevel(5)]
          },
          children: [
            {
              path: 'add',
              name: 'admin-master-items-add',
              component: () => import('@/views/admin/AddMasterItemView.vue'),
              meta: {
                layout: DefaultLayout,
                title: 'Add Master Item',
                middleware: [auth, userLevel(5)]
              }
            },
            {
              path: 'edit/:id',
              name: 'admin-master-items-edit',
              component: () => import('@/views/admin/EditMasterItemView.vue'),
              meta: {
                layout: DefaultLayout,
                title: 'Edit Master Item',
                middleware: [auth, userLevel(5)]
              }
            }
          ]
        },
        {
          path: 'merge-records',
          name: 'admin-merge-records',
          component: () => import('@/views/admin/merge-records/MergeRecordsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Merge Master Items',
            middleware: [auth, userLevel(5)]
          },
          children: [
            {
              path: ':itemToKeep/:itemToDiscard',
              name: 'admin-master-items-merge-records-id',
              component: () => import('@/views/admin/merge-records/MergeRecordsView.vue'),
              meta: {
                layout: DefaultLayout,
                title: 'Merge Duplicate Records',
                middleware: [auth, userLevel(5)]
              }
            }
          ]
        },
        {
          path: 'review-queue',
          name: 'admin-review-queue',
          component: () => import('@/views/admin/review-queue/ReviewQueueListView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Review New Master Items',
            middleware: [auth, userLevel(5)]
          },
          children: [
            {
              path: ':id',
              name: 'admin-review-queue-id',
              component: () => import('@/views/admin/review-queue/ReviewQueueItemView.vue'),
              meta: {
                layout: DefaultLayout,
                title: 'Review New Master Items',
                middleware: [auth, userLevel(5)]
              }
            }
          ]
        },
        {
          path: 'manage-organizations',
          name: 'admin-manage-organizations',
          component: () => import('@/views/admin/ManageOrganizationsView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Organizations',
            middleware: [auth, userLevel(5), permission(UserPermissionsEnum.ModifyOrganization)]
          }
        },
        {
          path: 'manage-users',
          name: 'admin-manage-users',
          component: () => import('@/views/admin/ManageUsersView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Users',
            middleware: [auth, userLevel(5)]
          }
        },
        {
          path: 'manage-orders',
          name: 'admin-manage-orders',
          component: () => import('@/views/admin/ManageOrdersView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Orders',
            middleware: [auth, userLevel(5)]
          }
        },
        {
          path: 'manage-plans',
          name: 'admin-manage-plans',
          component: () => import('@/views/admin/ManagePlansView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Manage Plans',
            middleware: [auth, userLevel(9)]
          }
        },
        {
          path: 'plans',
          name: 'admin-plans',
          children: [
            {
              path: 'add',
              name: 'admin-plans-add',
              component: () => import('@/views/admin/AddPlanView.vue'),
              meta: {
                layout: DefaultLayout,
                title: 'Backbar | Add Plan',
                middleware: [auth, userLevel(9)]
              }
            },
            {
              path: 'edit/:id',
              name: 'admin-plans-edit',
              component: () => import('@/views/admin/EditPlanView.vue'),
              meta: {
                layout: DefaultLayout,
                title: 'Backbar | Edit Plan',
                middleware: [auth, userLevel(9)]
              }
            }
          ]
        },
        {
          path: 'link-master-items',
          name: 'admin-link-master-items',
          component: () => import('@/views/admin/LinkMasterItemsToVipProductView.vue'),
          meta: {
            layout: DefaultLayout,
            title: 'Link Master Items',
            middleware: [auth, userLevel(5)]
          }
        }
      ]
    },
    {
      path: '/pos-auth/square-callback',
      name: 'square-callback',
      component: SquareCallbackComponent
    },
    {
      path: '/pos-auth/clover-callback',
      name: 'clover-callback',
      component: CloverCallbackComponent
    },
    {
      path: '/pos-auth/shift4-callback',
      name: 'shift4-callback',
      component: Shift4CallbackComponent
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: PageNotFoundView
    }
  ]
})

const middlewarePipeline = (context: RouteContext, middleware: any, index: number) => {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) {
    return context.next
  }
  return async () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1)
    await nextMiddleware({ ...context, next: nextPipeline })
  }
}

router.beforeEach(async (to, from, next) => {
  try {
    const subDomain = window.location.host.split('.')[0]
    if (subDomain === 'menu' && to.name !== 'menu-widget') {
      return next({ name: 'menu-widget' })
    }
    if (to.name === 'login') {
      return next()
    }
    if (!to.meta.middleware) {
      return next()
    }
    if (to.fullPath.indexOf('verify-flow') !== -1) {
      sessionStorage.setItem('verifyFlow', 'true')
    }
    const gtmStore = useGtmStore()
    const userStore = useUserStore()
    const itemsStore = useItemsStore()
    const countriesStore = useCountriesStore()
    const statesStore = useStatesStore()
    const didbuyAuditLogStore = useDidbuyAuditLogStore()
    const venuePosStore = useVenuePosStore()
    await userStore.getUser()
    const { name, plan } = userStore.currentOrganization
    const { currentVenue } = userStore
    const { id, email, permissions = {} as UserPermissionsType } = userStore.user
    const promises = []
    if (userStore.currentVenue?.id && !itemsStore.items.length) {
      promises.push(itemsStore.getItems())
    }
    if (currentVenue?.pos?.pos_type_id) {
      promises.push(venuePosStore.getVenuePos())
    }
    await Promise.all(promises)
    if (!countriesStore.countries.length) {
      countriesStore.getCountries()
    }
    if (!statesStore.states.length) {
      statesStore.getStates()
    }
    if (currentVenue?.id && !didbuyAuditLogStore.didbuyAuditLog.length) {
      didbuyAuditLogStore.getDidbuyAuditLog()
    }
    const { items } = itemsStore
    const { vip_verification_status } = userStore.currentVenue
    gtmStore.currentOrganization.name = name
    gtmStore.currentOrganization.plan = plan
    gtmStore.currentVenue.vip_verification_status = vip_verification_status
    gtmStore.user.id = id
    gtmStore.user.email = email
    gtmStore.items = items
    const alertsStore = useAlertsStore()
    alertsStore.$reset()
    const middleware: any = to.meta.middleware || []
    const context: RouteContext = {
      to,
      from,
      next
    }

    // TODO: Create a user setup middleware
    if (
      to.name !== 'welcome' &&
      to.name !== 'signup-confirm' &&
      (!userStore.user.first_name || !userStore.user.last_name)
    ) {
      return next({ name: 'welcome' })
    }

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  } catch (e) {
    return next({ name: 'login' })
  }
})

router.afterEach((to, from) => {
  if (from.path === '/orders/cart') {
    const cartStore = useShoppingCartStore()
    cartStore.resetShoppingCartStore()
  }
})

export default router
