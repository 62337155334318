<template>
  <div :class="['nav navbar navbar-static-top white-bg py-2']">
    <PrimeVueButton
      :severity="ButtonTypes.Secondary"
      :icon="Icons.Bars"
      class="navbar-minimalize"
      raised />
    <NavBarVenueComponent></NavBarVenueComponent>
    <NavBarIconMenuComponent></NavBarIconMenuComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ButtonTypes, Icons } from '@/enums/components.enum'
import NavBarVenueComponent from '@/components/NavBar/NavBarVenueComponent.vue'
import NavBarIconMenuComponent from '@/components/NavBar/NavBarIconMenuComponent.vue'
import PrimeVueButton from 'primevue/button'

export default defineComponent({
  components: { NavBarVenueComponent, NavBarIconMenuComponent, PrimeVueButton },
  name: 'NavBar Component',
  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      ButtonTypes,
      Icons
    }
  }
})
</script>
