<template lang="pug">
  #wrapper
    noscript
      iframe(src='https://www.googletagmanager.com/ns.html?id=GTM-TVW4RG4' height='0' width='0' style='display:none;visibility:hidden')
    SidebarComponent
    #page-wrapper.gray-bg.d-flex.flex-column
      NavBarComponent
      #content-wrapper
        #slot-wrapper(:class="scroll")
          slot
      footer-component
    #didbuys-check-modal.modal.fade(aria-hidden="true" data-backdrop="static" data-keyboard="false" role="dialog" tabindex="-2")
      .modal-dialog.modal-md
        .modal-content
          .modal-body
            .text-center
              h2#didbuys-check-modal-title.font-20 Account setup complete!
              span.d-block.font-14.mt-3
                | We've imported {{ unseenAuditLog.length }} items. See all of your products in your inventory items.
          .modal-footer.d-flex.justify-content-around
            ButtonComponent.btn-info.w-100(@click="handleShowDidbuyItemsModal")
              | View my items

    #didbuys-running-modal.modal.fade(aria-hidden="true" data-backdrop="static" data-keyboard="false" role="dialog" tabindex="-2")
      .modal-dialog.modal-md
        .modal-content
          .modal-body
            .text-center
              h2#didbuys-check-modal-title.font-20 We're almost done setting up your inventory!
              span.d-block.font-14.mt-3
                | We'll send you a notification when we're done.
          .modal-footer.d-flex.justify-content-around
            ButtonComponent.btn-info.w-100(@click="handleCloseModal")
              | Close

    ModalComponent(:isVisible="isSupportModalOpen")
      template(#headerContent) 
        h4 Submit a support ticket
      template(#body)
        HubspotForm
  </template>
<script lang="ts">
import { defineComponent } from 'vue'
import '@/assets/css/drinks.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/animate.css'
import '@/assets/css/inspinia.css'
import 'flatpickr/dist/flatpickr.min.css'
import 'metismenujs/style'
import '@/assets/css/styles.styl'
import 'vue-select/dist/vue-select.css'
import 'bootstrap'
import '@/js/popper-setup.js'
import { MetisMenu } from 'metismenujs'
import '@/js/ajax-setup.js'
import '@/js/utils.js'
import '//js.hs-scripts.com/5163161.js'
import SidebarComponent from '@/components/SidebarComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import '@/js/libraries/plugins/pace/pace.min.js'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user.js'
import { useStatesStore } from '@/stores/states.js'
import { useCountriesStore } from '@/stores/countries.js'
import { useDidbuyAuditLogStore } from '@/stores/didbuy-audit-log'
import { ButtonTypes } from '@/enums/components.enum'
import NavBarComponent from '@/components/NavBar/NavBarComponent.vue'
import { VipVerificationStatusesEnum } from '@/types/venue.type'
import HubspotForm from '@/components/HubspotForm.vue'
import { useSupportModalStore } from '@/stores/support-modal'
import ModalComponent from '@/components/ModalComponent.vue'

export default defineComponent({
  components: { FooterComponent, SidebarComponent, NavBarComponent, HubspotForm, ModalComponent },
  setup() {
    return {
      ButtonTypes
    }
  },
  data() {
    return {
      signupRouteNames: [
        'welcome',
        'create-organization',
        'verify-account',
        'create-inventory-locations',
        'distributor-information',
        'upload-inventory-spreadsheet'
      ],
      auditLog: [],
      unwatchers: []
    }
  },

  computed: {
    ...mapState(useUserStore, ['user', 'currentVenue', 'venues']),
    ...mapState(useStatesStore, ['states']),
    ...mapState(useCountriesStore, ['countries']),
    ...mapState(useSupportModalStore, ['isSupportModalOpen']),
    ...mapActions(useUserStore, ['getUser']),
    unseenAuditLog() {
      return this.auditLog.filter((log) => log.seen === 0)
    },
    seenAuditLog() {
      return this.auditLog.filter((log) => log.seen === 1)
    },
    scroll() {
      const scrollMeta = this.$route.meta?.scroll
      return scrollMeta === undefined || scrollMeta ? 'scroll' : ''
    }
  },

  async created() {
    document.body.classList.add('fixed-sidebar')
    document.body.classList.remove('gray-bg')
  },
  mounted() {
    this.initInspiniaTemplate()
  },
  watch: {
    'currentVenue.vip_verification_status': {
      handler(newValue, oldValue) {
        if (
          newValue === VipVerificationStatusesEnum.VERIFIED &&
          oldValue !== VipVerificationStatusesEnum.VERIFIED &&
          oldValue !== undefined
        ) {
          const unWatchLastDibuyCheck = this.$watch(
            'currentVenue.last_didbuy_check',
            () => {
              const unwatchRoute = this.$watch(
                'this.$route.name',
                () => {
                  if (this.$route.name && !this.signupRouteNames.includes(this.$route.name)) {
                    this.checkInitialDidbuysStatus()
                  }
                },
                { immediate: true }
              )
              this.unwatchers.push(unwatchRoute)
            },
            { immediate: true }
          )
          this.unwatchers.push(unWatchLastDibuyCheck)
        }
      },
      immediate: true,
      flush: 'post'
    }
  },
  methods: {
    ...mapActions(useUserStore, ['logoutUser']),
    handleCloseModal() {
      $('.modal').modal('hide')
    },
    async handleShowDidbuyItemsModal() {
      await $.ajax({
        url: '/didbuys/audit-log',
        data: { seen: 1, venue_id: this.currentVenue.id },
        method: 'PATCH'
      })
      this.handleCloseModal()
      this.$router.push({ name: 'inventory-manage' })
    },
    async checkInitialDidbuysStatus() {
      const venuesThatSawRunningModal: number[] = JSON.parse(
        sessionStorage.getItem('seenDidbuyCheckRunningModal') || '[]'
      )
      try {
        const didbuyAuditLogStore = useDidbuyAuditLogStore()
        if (!didbuyAuditLogStore.didbuyAuditLog.length) {
          await didbuyAuditLogStore.getDidbuyAuditLog()
        }
        this.auditLog = [...didbuyAuditLogStore.didbuyAuditLog]
        if (
          this.currentVenue.last_didbuy_check && // Processing Items from didbuys to backbar DB completed
          this.unseenAuditLog.length // There are items added from didbuys to check
        ) {
          this.$nextTick(() => {
            setTimeout(() => {
              $('#didbuys-check-modal').modal('show')
              this.unwatchers.forEach((unwatcher) => unwatcher())
            }, 1000)
          })
        } else if (
          this.currentVenue.didbuy_build_missing === 1 && // Waiting on didbuys
          !this.currentVenue.last_didbuy_check && // Processing Items from didbuys is NOT complete (is still running)
          !venuesThatSawRunningModal.includes(this.currentVenue.id)
        ) {
          venuesThatSawRunningModal.push(this.currentVenue.id)
          sessionStorage.setItem(
            'seenDidbuyCheckRunningModal',
            JSON.stringify(venuesThatSawRunningModal)
          )
          this.$nextTick(() => {
            setTimeout(() => {
              $('#didbuys-running-modal').modal('show')
            }, 1000)
          })
        }

        // if (this.seenAuditLog.length) {
        //   sessionStorage.setItem('seenDidbuyCheckRunningModal', 'true')
        // } else if (
        //   this.unseenAuditLog.length &&
        //   sessionStorage.getItem('seenDidbuyCheckRunningModal') !== 'true'
        // ) {
        //   $('#didbuys-check-modal').modal('show')
        //   sessionStorage.setItem('seenDidbuyCheckRunningModal', 'true')
        // } else if (sessionStorage.getItem('seenDidbuyRunning') !== 'true') {
        //   sessionStorage.setItem('seenDidbuyRunning', 'true')
        //   $('#didbuys-running-modal').modal('show')
        //   return
        // }
      } catch (e) {
        console.error(e)
      }
    },
    SmoothlyMenu() {
      if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        $('#side-menu').hide()
        // For smoothly turn on menu
        setTimeout(() => {
          $('#side-menu').fadeIn(400)
        }, 200)
      } else if ($('body').hasClass('fixed-sidebar')) {
        $('#side-menu').hide()
        setTimeout(() => {
          $('#side-menu').fadeIn(400)
        }, 100)
      } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $('#side-menu').removeAttr('style')
      }
    },
    initInspiniaTemplate() {
      try {
        // Fast fix bor position issue with Propper.js
        // Will be fixed in Bootstrap 4.1 - https://github.com/twbs/bootstrap/pull/24092
        ;(window as any).Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

        // // Add body-small class if window less than 768px
        if ($(window).width() < 769) {
          $('body').addClass('body-small')
        } else {
          $('body').removeClass('body-small')
        }

        // MetisMenu
        const sideMenu = new MetisMenu('#side-menu', {
          // toggle: true
        })

        // Collapse ibox function
        $('.collapse-link').on('click', function (e) {
          e.preventDefault()
          const ibox = $(this).closest('div.ibox')
          const button = $(this).find('i')
          const content = ibox.children('.ibox-content')
          content.slideToggle(200)
          button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down')
          ibox.toggleClass('').toggleClass('border-bottom')
          setTimeout(() => {
            ibox.resize()
            ibox.find('[id^=map-]').resize()
          }, 50)
        })

        // Close ibox function
        $('.close-link').on('click', function (e) {
          e.preventDefault()
          const content = $(this).closest('div.ibox')
          content.remove()
        })

        // Fullscreen ibox function
        $('.fullscreen-link').on('click', function (e) {
          e.preventDefault()
          const ibox = $(this).closest('div.ibox')
          const button = $(this).find('i')
          $('body').toggleClass('fullscreen-ibox-mode')
          button.toggleClass('fa-expand').toggleClass('fa-compress')
          ibox.toggleClass('fullscreen')
          setTimeout(() => {
            $(window).trigger('resize')
          }, 100)
        })

        // Close menu in canvas mode
        $('.close-canvas-menu').on('click', (e) => {
          e.preventDefault()
          $('body').toggleClass('mini-navbar')
          this.SmoothlyMenu()
        })

        // Open close right sidebar
        $('.right-sidebar-toggle').on('click', function (e) {
          e.preventDefault()
          $('#right-sidebar').toggleClass('sidebar-open')
        })

        // Small todo handler
        $('.check-link').on('click', function () {
          const button = $(this).find('i')
          const label = $(this).next('span')
          button.toggleClass('fa-check-square').toggleClass('fa-square-o')
          label.toggleClass('todo-completed')
          return false
        })

        // Append config box / Only for demo purpose
        // Uncomment on server mode to enable XHR calls
        //$.get("skin-config.html", function (data) {
        //    if (!$('body').hasClass('no-skin-config'))
        //        $('body').append(data);
        //});

        // Minimalize menu
        $('.navbar-minimalize').on('click', (event) => {
          event.preventDefault()
          $('body').toggleClass('mini-navbar')
          this.SmoothlyMenu()
        })

        // Tooltips demo
        $('.tooltip-demo').tooltip({
          selector: '[data-toggle=tooltip]',
          container: 'body'
        })

        // Move right sidebar top after scroll
        $(window).scroll(() => {
          if ($(window).scrollTop() > 0 && !$('body').hasClass('fixed-nav')) {
            $('#right-sidebar').addClass('sidebar-top')
          } else {
            $('#right-sidebar').removeClass('sidebar-top')
          }
        })

        $('[data-toggle=popover]').popover()

        // Minimalize menu when screen is less than 768px
        $(window).bind('resize', function () {
          if (($(this).width() || 0) < 769) {
            $('body').addClass('body-small')
          } else {
            $('body').removeClass('body-small')
          }
        })

        // Local Storage functions
        // Set proper body class and plugins based on user configuration
        if (this.localStorageSupport()) {
          const collapse = localStorage.getItem('collapse_menu')
          const fixedsidebar = localStorage.getItem('fixedsidebar')
          const fixednavbar = localStorage.getItem('fixednavbar')
          const boxedlayout = localStorage.getItem('boxedlayout')
          const fixedfooter = localStorage.getItem('fixedfooter')

          const body = $('body')

          if (fixedsidebar == 'on') {
            body.addClass('fixed-sidebar')
          }

          if (collapse == 'on') {
            if (body.hasClass('fixed-sidebar')) {
              if (!body.hasClass('body-small')) {
                body.addClass('mini-navbar')
              }
            } else {
              if (!body.hasClass('body-small')) {
                body.addClass('mini-navbar')
              }
            }
          }

          if (fixednavbar == 'on') {
            $('.navbar-static-top').removeClass('navbar-static-top').addClass('navbar-fixed-top')
            body.addClass('fixed-nav')
          }

          if (boxedlayout == 'on') {
            body.addClass('boxed-layout')
          }

          if (fixedfooter == 'on') {
            $('.footer').addClass('fixed')
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    localStorageSupport() {
      return 'localStorage' in window && window['localStorage'] !== null
    }
  },
  head() {
    const headObject: any = {
      title: this.$route?.meta?.title || 'Backbar'
    }
    return headObject
  }
})
</script>
