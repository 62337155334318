<template>
  <Dialog
    modal
    v-model:visible="localVisible"
    closeIcon="fa fa-xmark"
    :style="{ width: modalWidth ? modalWidth : '700px' }"
    :breakpoints="{ '1199px': '700px', '750px': '90vw' }"
    @hide="onHide"
    @after-hide="onAfterHide"
    :draggable="false"
    @close="onHide">
    <template #header v-if="hasHeaderSlot">
      <slot name="headerContent"></slot>
    </template>
    <slot name="body"></slot>
    <template #footer v-if="hasFooterSlot">
      <slot name="footerContent"></slot>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import Dialog from 'primevue/dialog'

export default defineComponent({
  name: 'ModalComponent',
  components: {
    Dialog
  },
  props: {
    modalWidth: {
      type: String,
      default: '700px'
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: false
    }
  },
  setup(props, { emit, slots }) {
    const localVisible = ref(props.isVisible)

    watch(
      () => props.isVisible,
      (newVal) => {
        localVisible.value = newVal
      }
    )

    watch(localVisible, (newVal) => {
      emit('update:isVisible', newVal)
    })

    const onHide = () => {
      emit('update:isVisible', false)
    }

    const onAfterHide = () => {
      emit('after-hide')
    }

    const hasFooterSlot = computed(() => !!slots.footerContent)
    const hasHeaderSlot = computed(() => !!slots.headerContent)

    const trackCloseButtonClick = (event: PointerEvent) => {
      const closeButton = document.querySelector('.p-dialog-close-button')
      if (closeButton && closeButton.contains(event.target as Node) && props.onClose) {
        props.onClose()
      }
    }

    onMounted(() => {
      document.addEventListener('click', trackCloseButtonClick)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('click', trackCloseButtonClick)
    })

    return {
      localVisible,
      onHide,
      onAfterHide,
      hasFooterSlot,
      hasHeaderSlot
    }
  }
})
</script>

<style>
@import '@/assets/css/backbarVariables.css';

.p-dialog-close-button {
  color: var(--icon-default) !important;
}

.p-dialog-content {
  border-top: 1px solid var(--border-default-color) !important;
}

.p-dialog-footer {
  border-top: 1px solid var(--border-default-color) !important;
}
</style>
