import { defineStore } from 'pinia'

interface State {
  isSupportModalOpen: boolean
}

export const useSupportModalStore = defineStore('supportModal', {
  state: (): State => {
    return {
      isSupportModalOpen: false
    }
  },
  actions: {
    openSupportModal() {
      if (!this.isSupportModalOpen) {
        this.isSupportModalOpen = true
      }
    },
    closeSupportModal() {
      if (this.isSupportModalOpen) {
        this.isSupportModalOpen = false
      }
    }
  }
})
