<template>
  <div class="footer d-none d-md-block">
    <div class="pull-right"></div>
    <div>Backbar &copy; {{ currentYear }}</div>
  </div>
  <div class="footer mobile-app-download-bar d-md-none" v-show="!route?.query?.mobile_report">
    Download the Backbar Mobile App
    <span class="mobile-app-icons">
      <a href="https://play.google.com/store/apps/details?id=com.backbar" target="_blank">
        <i class="fab fa-android"></i>
      </a>
    </span>
    <span class="mobile-app-icons">
      <a href="https://itunes.apple.com/us/app/backbar/id1461796151?ls=1&mt=8" target="_blank">
        <i class="fab fa-apple"></i>
      </a>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const currentYear = computed(() => new Date().getFullYear())
</script>
