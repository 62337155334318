import type { VendorContactType } from '@/types/vendor-contact.type.js'
import type {
  VendorDeliveryDayType,
  VendorOTCDeliveryDayType
} from '@/types/vendor-delivery-day.type.js'
import type { VendorType } from '@/types/vendor.type'

export const defaultNewContact = {
  name: '',
  email_address: '',
  phone_number: '',
  role: ''
} as VendorContactType

export const defaultNewDeliveryDay = {
  delivery_day: '',
  cutoff_time: '',
  cutoff_day: '',
  delete: false
} as VendorDeliveryDayType

export const defaultCurrentVendor: VendorType = {
  id: 0,
  master_id: 0,
  name: '',
  account_number: '',
  vip_account_code: '',
  vendor_notes: '',
  venue_id: 0,
  warehoused: 0,
  create_date: 0,
  sends_cutoff_notification: 0,
  vip_distributor_id: '',
  vip_distributor_isv_code: '',
  vip_distributor_source_code: '',
  vip_retailer_id: '',
  vip_sm_code: '',
  amount_owed: 0,
  otc_enabled: false,
  contacts: [] as VendorContactType[],
  deliveryDays: [] as VendorDeliveryDayType[],
  otcDeliveryDays: [] as VendorOTCDeliveryDayType[]
}

export const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const hoursOfTheDay = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM'
]
